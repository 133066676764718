/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState, useCallback } from 'react';
import { ethers, BigNumber } from 'ethers';
import cn from 'classnames';
import { Player } from '@lottiefiles/react-lottie-player';
import { isTablet, isMobileOnly, isDesktop } from 'react-device-detect';
import { Clouds, House, ModalMinting, ModalFailed } from 'components';
import { Modal } from 'atoms';
import { Statuses } from 'models';
import planetAnimation from 'assets/lottie/planet_lottie.json';
import { FeldmanSistersNFTAddress, PRICE_IN_ETHER } from '../../constants';
import FeldmanSistersNFT from '../../FeldmanSistersNFT.json';
import styles from './Home.module.scss';

const getContract = () => {
  const signer = new ethers.providers.Web3Provider(window.ethereum as unknown).getSigner();
  return new ethers.Contract(FeldmanSistersNFTAddress, FeldmanSistersNFT.abi, signer);
};

const INITIAL_COUNT = 1;

const Home = () => {
  const [walletID, setWalletID] = useState<null | string[]>(null);
  const handleDisconnectWallet = () => setWalletID(null);

  const [isWhiteList, setWhiteList] = useState<boolean>(null);
  const [freeMintLeft, setFreeMintLeft] = useState<number>(null);

  const [mintLeft, setMintLeft] = useState<string>(null);

  const [count, setCount] = useState(INITIAL_COUNT);

  const [modalID, setModalID] = useState<Statuses>(null);
  const onCloseModal = useCallback(() => setModalID(null), []);
  const onHaveNoWallet = () => setModalID(Statuses.failed);

  const onRequestAccount = async () => {
    try {
      const account = await window.ethereum.request({
        method: 'eth_requestAccounts'
      });

      const contract = getContract();
      const isWhiteList = await contract.whitelist(account[0]);

      if (isWhiteList) {
        const availableFreeMint = (await contract.whitelistPerWallet()) - (await contract.whitelistMinted(account[0]));
        setFreeMintLeft(availableFreeMint);
      }

      setWalletID(account as string[]);
      setWhiteList(isWhiteList);
    } catch (err) {
      console.error('Error connecting... error: ', err);
    }
  };

  const onGetTotalSupply = async () => {
    try {
      const contract = getContract();
      const totalMinted = await contract.totalSupply();

      setMintLeft(totalMinted.toString());
    } catch (err) {
      console.error('onGetTotalSupply err ', err);
    }
  };

  const handleConnectWallet = async () => {
    if (window.ethereum) {
      await onRequestAccount();
      await onGetTotalSupply();
      return;
    }
    onHaveNoWallet();
  };

  const onSubmitMint = useCallback(
    async (count: number) => {
      if (!window.ethereum) {
        onHaveNoWallet();
        return;
      }

      if (!walletID) await onRequestAccount();

      setModalID(Statuses.pending);

      try {
        const contract = getContract();
        const mintCount = await contract.getMintCount(count);

        const normalMint = mintCount?.normalMint_?.toNumber();
        const whitelistMint = mintCount?.whitelistMint_?.toNumber();

        const totalMint = whitelistMint ? normalMint + whitelistMint : normalMint;
        const totalPrice = (PRICE_IN_ETHER * normalMint).toString();

        await contract.mint(BigNumber.from(totalMint), { value: ethers.utils.parseEther(totalPrice) });
        await onGetTotalSupply();

        setModalID(Statuses.complete);
      } catch (err) {
        setModalID(Statuses.transaction_failed);
        console.error('onSubmitMint err ', err);
      }
    },
    [walletID]
  );

  const getModalContent = useCallback(
    (id: Statuses) => {
      switch (id) {
        case Statuses.failed:
        case Statuses.transaction_failed:
          return <ModalFailed status={id} onCloseModal={onCloseModal} />;

        case Statuses.complete:
        case Statuses.pending:
          return <ModalMinting count={count} status={id} onCloseModal={onCloseModal} />;

        default:
          return null;
      }
    },
    [onCloseModal, count]
  );

  return (
    <React.Fragment>
      <div
        className={cn(styles.wrapper, {
          [styles.isMobile]: isMobileOnly,
          [styles.isTablet]: isTablet,
          [styles.isDesktop]: isDesktop
        })}
      >
        <Clouds />

        <a
          href="https://linktr.ee/magrathea_nft"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.planetLink}
        >
          <Player src={planetAnimation} autoplay loop />
        </a>

        <House
          walletID={walletID}
          onConnectWallet={handleConnectWallet}
          onDisconnectWallet={handleDisconnectWallet}
          count={count}
          mintLeft={mintLeft}
          isWhiteList={isWhiteList}
          freeMintLeft={freeMintLeft}
          onSetCount={setCount}
          onSubmitMint={onSubmitMint}
        />
        <div className={styles.outerShadow} />
      </div>

      <Modal open={!!modalID} onClose={onCloseModal}>
        {getModalContent(modalID)}
      </Modal>
    </React.Fragment>
  );
};

export default Home;
