import React, { FC, useState, useEffect } from 'react';
import cn from 'classnames';
import { isTablet, isMobileOnly, isDesktop } from 'react-device-detect';
import { Cards, Girls, MintForm } from 'components';
import { Button } from 'atoms';
import { BIG_CARDS } from './constants';
import styles from './House.module.scss';

const CUT_START = 0;
const CUT_LENGTH = 4;

const MAX_PIC_IDX = 5;
const INIT_PIC_IDX = 0;

const INCREMENT = 1;
const INTERVAL_TIMEOUT = 1000;

const displayWalletKey = (key: string) => `${key.slice(CUT_START, CUT_LENGTH)}...${key.slice(-CUT_LENGTH)}`;

type Props = {
  walletID: string[] | null;
  onConnectWallet: () => void;
  onDisconnectWallet: () => void;
  count: number;
  onSetCount: React.Dispatch<React.SetStateAction<number>>;
  mintLeft: string;
  isWhiteList: null | boolean;
  freeMintLeft: number;
  onSubmitMint: (count: number) => void;
};

const House: FC<Props> = ({
  walletID,
  onConnectWallet,
  onDisconnectWallet,
  count,
  onSetCount,
  mintLeft,
  isWhiteList,
  freeMintLeft,
  onSubmitMint
}) => {
  const [isHovered, setHovered] = useState(false);

  const getWalletBtnContent = () => {
    if (!isHovered && walletID) return displayWalletKey(walletID[0]);
    if (walletID && isHovered) return 'Disconnect';

    return 'Connect wallet';
  };

  const [idx, setIdx] = useState<number>(INIT_PIC_IDX);

  useEffect(() => {
    const intervalID = setInterval(() => {
      setIdx((prev) => (prev === MAX_PIC_IDX ? INIT_PIC_IDX : (prev += INCREMENT)));
    }, INTERVAL_TIMEOUT);

    return () => {
      clearInterval(intervalID);
    };
  }, []);

  return (
    <div
      className={cn(styles.house, {
        [styles.isMobile]: isMobileOnly,
        [styles.isTablet]: isTablet,
        [styles.isDesktop]: isDesktop
      })}
    >
      <Button
        onClick={walletID ? onDisconnectWallet : onConnectWallet}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        buttonClass={styles.walletBtn}
      >
        {getWalletBtnContent()}
      </Button>

      <h2 className={styles.pictureTitle}>{BIG_CARDS[idx].title}</h2>

      <Cards img={BIG_CARDS[idx].img} />
      <Girls />
      <MintForm
        count={count}
        onSetCount={onSetCount}
        mintLeft={mintLeft}
        isWhiteList={isWhiteList}
        freeMintLeft={freeMintLeft}
        onSubmitMint={onSubmitMint}
      />

      <div className={styles.sidewallPicture} />
      <div className={styles.feldmanLabel} />
      <div className={styles.pieceOfWall} />
    </div>
  );
};

export default House;
