import React, { FC } from 'react';
import cn from 'classnames';
import { isTablet, isMobileOnly, isDesktop } from 'react-device-detect';
import cardMin1 from 'assets/images/card_min_desk_1.png';
import cardMin2 from 'assets/images/card_min_desk_2.png';
import cardMin3 from 'assets/images/card_min_desk_3.png';
import cardMin4 from 'assets/images/card_min_desk_4.png';
import cardMin5 from 'assets/images/card_min_desk_5.png';
import styles from './Cards.module.scss';

type Props = {
  img: string;
};

const Cards: FC<Props> = ({ img }) => {
  return (
    <div
      className={cn(styles.cards, {
        [styles.isMobile]: isMobileOnly,
        [styles.isTablet]: isTablet,
        [styles.isDesktop]: isDesktop
      })}
    >
      <div className={styles.cardBig}>
        <img src={img} alt="_" className="img-fluid" />
      </div>
      <div className={cn(styles.cardSmall, styles.cardSmall1)}>
        <img src={cardMin1} alt="_" className="img-fluid" />
      </div>
      <div className={cn(styles.cardSmall, styles.cardSmall2)}>
        <img src={cardMin2} alt="_" className="img-fluid" />
      </div>
      <div className={cn(styles.cardSmall, styles.cardSmall3)}>
        <img src={cardMin3} alt="_" className="img-fluid" />
      </div>
      <div className={cn(styles.cardSmall, styles.cardSmall4)}>
        <img src={cardMin4} alt="_" className="img-fluid" />
      </div>
      <div className={cn(styles.cardSmall, styles.cardSmall5)}>
        <img src={cardMin5} alt="_" className="img-fluid" />
      </div>
    </div>
  );
};

export default Cards;
