import React from 'react';
import cn from 'classnames';
import { isTablet, isMobileOnly, isDesktop } from 'react-device-detect';
import styles from './Clouds.module.scss';

const Clouds = () => {
  return (
    <div
      className={cn({
        [styles.isMobile]: isMobileOnly,
        [styles.isTablet]: isTablet,
        [styles.isDesktop]: isDesktop
      })}
    >
      <div className={styles.clouds}>
        <div className={styles.cloudGroupLeft} />
        <div className={styles.cloudGroupRight} />
        <div className={styles.cloudRight} />
      </div>
    </div>
  );
};

export default Clouds;
