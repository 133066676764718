import React from 'react';
import cn from 'classnames';
import { isTablet, isMobileOnly, isDesktop } from 'react-device-detect';
import sisterNicole from 'assets/gifs/girl_left_animate.gif';
import sisterMishelle from 'assets/gifs/girl_right_animate.gif';
import styles from './Girls.module.scss';

const Girls = () => {
  return (
    <div
      className={cn({
        [styles.isMobile]: isMobileOnly,
        [styles.isTablet]: isTablet,
        [styles.isDesktop]: isDesktop
      })}
    >
      <div className={styles.girlLeft}>
        <img src={sisterNicole} alt="_" className="img-fluid" />
      </div>
      <div className={styles.girlRight}>
        <img src={sisterMishelle} alt="_" className="img-fluid" />
      </div>
    </div>
  );
};

export default Girls;
