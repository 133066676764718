import React, { FC } from 'react';
import cn from 'classnames';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import styles from './NumberInput.module.scss';

interface Props extends NumericFormatProps {
  inputBoxClass?: string;
  inputClass?: string;
  maxValue: number;
  afterComponent?: React.ReactNode;
}

const NumberInput: FC<Props> = ({
  value,
  maxValue,
  onChange,
  allowNegative = false,
  allowLeadingZeros = false,
  decimalScale = 0,
  inputBoxClass,
  inputClass,
  afterComponent = null,
  ...rest
}) => {
  return (
    <div className={cn(styles.inputBox, { [inputBoxClass]: inputBoxClass })}>
      <NumericFormat
        value={value}
        onChange={onChange}
        allowNegative={allowNegative}
        allowLeadingZeros={allowLeadingZeros}
        decimalScale={decimalScale}
        className={cn(styles.input, { [inputClass]: inputClass })}
        {...rest}
      />

      {afterComponent}
    </div>
  );
};

export default NumberInput;
