import React, { FC, useEffect, useRef, useCallback, useMemo } from 'react';
import cn from 'classnames';
import debounce from 'lodash/debounce';
import { isTablet, isMobileOnly, isDesktop } from 'react-device-detect';
import { Button, CountButton, NumberInput } from 'atoms';
import { OPEN_SEA_LINK, MAX_MINT_VALUE, PRICE_IN_ETHER } from '../../constants';
import { ReactComponent as OpenseaIcon } from 'assets/images/opensea_icon.svg';
import styles from './MintForm.module.scss';

const MIN_VALUE = 0;
const INCREMENT = 1;

const INCREMENT_INTERVAL = 100;
const BLOCK_INTERVAL = 500;

type Props = {
  count: number;
  onSetCount: React.Dispatch<React.SetStateAction<number>>;
  mintLeft: string;
  isWhiteList: boolean;
  freeMintLeft: number;
  onSubmitMint: (count: number) => void;
};

const MintForm: FC<Props> = ({ count, onSetCount, mintLeft, isWhiteList, freeMintLeft, onSubmitMint }) => {
  const handleChangeCount = (e) => onSetCount(+e.target.value);

  const maxValue = useMemo(() => (mintLeft ? MAX_MINT_VALUE - +mintLeft : MAX_MINT_VALUE), [mintLeft]);

  const handleDecrement = useCallback(() => {
    onSetCount((prev) => (prev === MIN_VALUE ? prev : (prev -= INCREMENT)));
  }, [onSetCount]);

  const handleIncrement = useCallback(() => {
    onSetCount((prev) => (prev === maxValue ? prev : (prev += INCREMENT)));
  }, [onSetCount, maxValue]);

  const intervalRef = useRef(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const startCounter = useCallback(
    debounce((onSetCounter: () => void) => {
      if (intervalRef.current) return;

      intervalRef.current = setInterval(() => onSetCounter(), INCREMENT_INTERVAL);
    }, BLOCK_INTERVAL),
    []
  );

  const stopCounter = useCallback(() => {
    startCounter.cancel();

    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  }, [startCounter]);

  useEffect(() => () => stopCounter(), [stopCounter]);

  const getMintButtonLabel = useCallback(() => {
    if (!isWhiteList) return `Mint for ${(PRICE_IN_ETHER * count).toFixed(2)} ETH`;
    if (isWhiteList && count <= freeMintLeft) return 'Claim for free';

    const difference = count - freeMintLeft;

    return `Mint for ${(PRICE_IN_ETHER * difference).toFixed(2)} ETH`;
  }, [count, freeMintLeft, isWhiteList]);

  return (
    <div
      className={cn(styles.wrapper, {
        [styles.isMobile]: isMobileOnly,
        [styles.isTablet]: isTablet,
        [styles.isDesktop]: isDesktop
      })}
    >
      <div className={styles.mintLeftBox}>
        <span
          className={cn(styles.mintLeft, { [styles.mintLeftShow]: mintLeft })}
        >{`${mintLeft} / ${MAX_MINT_VALUE}`}</span>
      </div>

      <div className={styles.countBox}>
        <CountButton
          color="red"
          onClick={handleDecrement}
          onMouseDown={() => startCounter(handleDecrement)}
          onMouseUp={stopCounter}
          onMouseLeave={stopCounter}
          buttonClass={styles.countButton}
          buttonShadowClass={styles.countBtnShadow}
        >
          -
        </CountButton>
        <div className={styles.decrementShadow} />

        <NumberInput
          value={count}
          maxValue={MAX_MINT_VALUE}
          onChange={handleChangeCount}
          isAllowed={({ value }) => +value <= maxValue}
          inputClass={styles.inputClass}
        />
        <div className={styles.inputShadow} />

        <CountButton
          color="green"
          onClick={handleIncrement}
          onMouseDown={() => startCounter(handleIncrement)}
          onMouseUp={stopCounter}
          onMouseLeave={stopCounter}
          buttonClass={styles.countButton}
          buttonShadowClass={styles.countBtnShadow}
        >
          +
        </CountButton>
        <div className={styles.incrementShadow} />
      </div>

      <div className={styles.buttonsRow}>
        <Button display="secondary" isLink href={OPEN_SEA_LINK} buttonClass={styles.actionButton}>
          View on Opensea <OpenseaIcon className={styles.openseaIcon} />
        </Button>
        <div className={styles.openseaBtnShadow} />

        <Button onClick={() => onSubmitMint(count)} isDisabled={!count} buttonClass={styles.actionButton}>
          {getMintButtonLabel()}
        </Button>
        <div className={styles.mintBtnShadow} />
      </div>
    </div>
  );
};

export default React.memo(MintForm);
