import React, { FC } from 'react';
import cn from 'classnames';
import styles from './Button.module.scss';

type Props = {
  children: React.ReactNode;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  isLink?: boolean;
  href?: string;
  display?: 'primary' | 'secondary';
  size?: 'sm' | 'md' | 'lg';
  buttonClass?: string;
  isDisabled?: boolean;
};

const Button: FC<Props> = ({
  children,
  onClick,
  onMouseEnter,
  onMouseLeave,
  isLink,
  href = '#',
  display = 'primary',
  size,
  buttonClass
}) => {
  const classes = cn(styles.button, {
    [styles[display]]: display,
    [styles[size]]: size,
    [buttonClass]: buttonClass
  });

  return isLink ? (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={classes}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </a>
  ) : (
    <button type="button" onClick={onClick} className={classes} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {children}
    </button>
  );
};

export default Button;
