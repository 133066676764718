import React, { FC } from 'react';
import cn from 'classnames';
import styles from './CountButton.module.scss';

type Props = {
  children: React.ReactNode;
  color: 'red' | 'green';
  onClick: () => void;
  onMouseDown: () => void;
  onMouseUp: () => void;
  onMouseLeave: () => void;
  buttonClass?: string;
  buttonShadowClass?: string;
};

const CountButton: FC<Props> = ({
  children,
  onClick,
  color = 'red',
  onMouseDown,
  onMouseUp,
  onMouseLeave,
  buttonClass,
  buttonShadowClass
}) => {
  return (
    <div className={styles.buttonBox}>
      <button
        type="button"
        onClick={onClick}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseLeave={onMouseLeave}
        onTouchStart={onMouseDown}
        onTouchCancel={onMouseUp}
        onTouchEnd={onMouseLeave}
        className={cn(styles.button, { [buttonClass]: buttonClass, [styles[color]]: color })}
      >
        {children}
      </button>

      <div className={cn(styles.buttonShadow, { [buttonShadowClass]: buttonShadowClass })} />
    </div>
  );
};

export default CountButton;
