import React, { FC } from 'react';
import cn from 'classnames';
import { Statuses } from 'models';
import { Button } from 'atoms';
import { ETHER_SCAN_LINK } from '../../constants';
import styles from './ModalContent.module.scss';

type ModalFailedProps = {
  status: Statuses;
  onCloseModal: () => void;
};

export const ModalFailed: FC<ModalFailedProps> = ({ status, onCloseModal }) => {
  const title = status === Statuses.transaction_failed ? 'Insufficient ballance' : 'Failed to connect...';
  const message =
    status === Statuses.transaction_failed
      ? 'Transfer funds to your wallet or add funds with a card.'
      : 'You need a metamask to connect a wallet.';

  return (
    <div className={styles.modalFailedContent}>
      <h3 className={styles.title}>{title}</h3>
      <p className={styles.text}>{message}</p>

      <Button onClick={onCloseModal} buttonClass={styles.button}>
        Cancel
      </Button>
    </div>
  );
};

type ModalMintingProps = {
  status: Statuses;
  count: number;
  onCloseModal: () => void;
};

export const ModalMinting: FC<ModalMintingProps> = ({ status, count, onCloseModal }) => {
  const isMultiple = count % 2 === 0;
  return (
    <div className={styles.modalMinting}>
      {status === Statuses.pending ? (
        <h3 className={styles.titlePending}>Transaction pending...</h3>
      ) : (
        <React.Fragment>
          <h3 className={styles.titleComplete}>Transaction complete!</h3>
          <p className={styles.textComplete}>{`You have successfully minted ${count} token${
            isMultiple ? 's' : ''
          }!`}</p>
        </React.Fragment>
      )}

      <div className={cn(styles.buttons, { [styles.buttonsComplete]: status === Statuses.complete })}>
        {status === Statuses.complete && (
          <Button isLink href={ETHER_SCAN_LINK} buttonClass={styles.buttonLink}>
            Check Etherscan
          </Button>
        )}

        <Button onClick={onCloseModal} buttonClass={styles.buttonExit}>
          Exit
        </Button>
      </div>
    </div>
  );
};
