import React, { FC, useLayoutEffect, useCallback, useRef } from 'react';
import cn from 'classnames';
import styles from './Modal.module.scss';

type Props = {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  overlayClass?: string;
  contentClass?: string;
};

const Modal: FC<Props> = ({ open, onClose, children, overlayClass, contentClass }) => {
  const contentRef = useRef<HTMLDivElement>();

  const onKeyDown = useCallback(
    ({ key }: KeyboardEvent) => {
      if (key !== 'Escape') return;
      onClose();
    },
    [onClose]
  );

  const onMouseDown = useCallback(
    ({ target }: MouseEvent) => {
      if (contentRef.current?.contains(target as Node)) return;
      onClose();
    },
    [onClose]
  );

  useLayoutEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    document.addEventListener('mousedown', onMouseDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('mousedown', onMouseDown);
    };
  }, [onKeyDown, onMouseDown]);

  return (
    <div className={cn(styles.overlay, { [styles.modalShow]: open, [overlayClass]: overlayClass })}>
      {children ? (
        <div ref={contentRef} className={cn(styles.content, { [contentClass]: contentClass })}>
          {children}
        </div>
      ) : null}
    </div>
  );
};

export default Modal;
