import cardBig_1 from 'assets/images/cardBig_1.png';
import cardBig_2 from 'assets/images/cardBig_2.png';
import cardBig_3 from 'assets/images/cardBig_3.png';
import cardBig_4 from 'assets/images/cardBig_4.png';
import cardBig_5 from 'assets/images/cardBig_5.png';
import cardBig_6 from 'assets/images/cardBig_6.png';

export const BIG_CARDS = [
  {
    img: cardBig_1,
    title: 'R2web3 dreamily on roller coaster eats noodles'
  },
  {
    img: cardBig_2,
    title: 'Ex with fork on roller coaster writes gods diary'
  },
  {
    img: cardBig_3,
    title: 'Live beer as bored lifeform on roller coaster rides a turtle'
  },
  {
    img: cardBig_4,
    title: 'Ancient kitten inside matrix in sheep wool milks a bull'
  },
  {
    img: cardBig_5,
    title: 'Greenboy dreamily on roller coaster cooks a bird'
  },
  {
    img: cardBig_6,
    title: 'Ex with fork on the heart of gold shouts out bazinga'
  }
];
